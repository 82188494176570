module.exports = {
  // 语言
  language: {
    products: '产品介绍',
    login: '登录',
    aboutUs: '关于我们',
    company: '企业简介',
    product: '产品',
    product1: '个性化陶瓷托槽',
    product2: '儿童早期矫治器',
    product3: '活动义齿',
    contact: '联系我们',
    customer: '客服热线',
    email: '邮箱留言',
    address: '地址：浙江省杭州市钱塘区科技园路57号新加坡科技园16幢905-906',
    becomeADoctor: '成为医生',
    privacyStatement: '隐私声明',
    termsOfUse: '使用条款',
    home: '首页',
    location: '您的当前位置:',
    create: '开创口腔诊疗AI新时代',
    intro1:
      '超齿医疗科技有限公司是一家由海内外高层次人才团队创建的集口腔新材料研发、生物力学分析、AI人工智能、全球最先进的3D打印和智能制造于一身的创新型高科技公司。',
    intro2:
      '传统口腔诊疗在正畸、种植和修复等项目存在流程长、耗时多、精准度低、患者体验感差、费用高等诸多痛点。超齿医疗计划提供多场景口腔诊疗需求的数字化解决方案，从智能制造、新材料、医疗服务和口腔数字化四大板块着手，全产业覆盖口腔诊疗领域，推出定量、可控、精准的口腔诊疗技术，赋能口腔诊所，实现正畸、种植和修复等口腔业务的精准化、数字化和智能化，为每个患者提供全生命周期的口腔服务和体验。',
    intro3: ' 超精、超快、超省',
    faster: '让美好改变更快发生',
    invisibleCorrection: '隐形矫正'
  }
}
