module.exports = {
  // 语言
  language: {
    login: 'login',
    aboutUs: 'About us',
    products: 'Product introduction',
    company: 'Company profile',
    product: 'product',
    product1: 'Personalized ceramic bracket',
    product2: 'Early childhood appliance',
    product3: 'Removable denture',
    contact: 'Contact us',
    customer: 'Customer service hotline',
    email: 'Email message',
    address:
      'Address: 905-906, Building 16, Singapore Science Park, 57 Science Park Road, Qiantang District, Hangzhou City, Zhejiang Province',
    becomeADoctor: 'Become a doctor',
    privacyStatement: 'Privacy Statement',
    termsOfUse: 'Terms of use',
    home: 'homePage',
    location: 'Your current location:',
    create: 'Create a new era of oral diagnosis and treatment AI',
    intro1:
      'Supertooth Medical Technology Co., Ltd. is an innovative high-tech company founded by a team of high-level talents at home and abroad, which integrates the research and development of new oral materials, biomechanical analysis, AI artificial intelligence, the worlds most advanced 3D printing and intelligent manufacturing.',
    intro2:
      'Traditional dental treatment in orthodontic, implant and repair projects have many pain points such as long process, time-consuming, low accuracy, poor patient experience, high cost. The Super Dental Medical plan provides digital solutions for multi-scenario oral diagnosis and treatment needs. Starting from the four major sectors of intelligent manufacturing, new materials, medical services and oral digitalization, the whole industry covers the field of oral diagnosis and treatment, and introduces quantitative, controllable and accurate oral diagnosis and treatment technologies to empower oral clinics and realize the precision, digitalization and intelligence of dental services such as orthodontics, implantation and restoration. Full life cycle oral services and experiences for each patient.',
    intro3: 'Super fine, super fast, super save',
    faster: 'Make good change happen faster',
    invisibleCorrection: 'Invisible correction'
  }
}
