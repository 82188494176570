import { createI18n } from 'vue-i18n'
import zh from '@/plugins/zh.js'
import en from '@/plugins/en.js'
const i18n = createI18n({
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
  legacy: false,
  // 设置默认地区
  locale: localStorage.getItem('lang') || 'zh',
  // // 备选区域
  // fallbackLocale: 'en',
  // 设置地区对应的语言
  messages: {
    zh,
    en
  }
})

export default i18n
