import { createStore } from 'vuex'

export default createStore({
  state: {
    id: 0
  },
  getters: {
  },
  mutations: {
    setId (state, id) {
      state.id = id
    }
  },
  actions: {
  },
  modules: {
  }
})
