import { createRouter, createWebHistory } from 'vue-router'
// import index from '@/views/index.vue'
// import VueRouter from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/Personalize',
    name: 'Personalize',
    component: () => import('@/views/Personalize.vue')
  },
  {
    path: '/ChildrenProduct',
    name: 'ChildrenProduct',
    component: () => import('@/views/ChildrenProduct.vue')
  },
  {
    path: '/FalseTooth',
    name: 'FalseTooth',
    component: () => import('@/views/FalseTooth.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('@/views/details/aboutUs.vue')
  // },
  // {
  //   path: '/product',
  //   name: 'product',
  //   component: () => import('@/views/details/product.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('@/views/home/index.vue')
  // },
  // {
  //   path: '/brand',
  //   name: 'brand',
  //   component: () => import('@/views/home/brand.vue')
  // },

  // {
  //   path: '/productkemp',
  //   name: 'productkemp',
  //   component: () => import('@/views/home/productkemp.vue')
  // }
]
const router = createRouter({
  history: createWebHistory(),
  routes,
  // 在这里定义滚动行为
  scrollBehavior (to) {
    if (to.hash) {
      // 检查是否有哈希，如果有，则滚动到锚点
      return {
        el: to.hash,
        behavior: 'smooth' // 使滚动平滑
      }
    }
    return { top: 0 } // 没有哈希时滚动到顶部
  }
})
// const router = new VueRouter({
//   mode: 'history',
//   base: '/',
//   routes
// })
export default router
